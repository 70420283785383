import { useEffect, useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableRow,
  TableCell,
} from "@mui/material";
import * as Yup from "yup";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// components
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import CommonTable from "src/components/CommonTable";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import dayjs from "dayjs";
import { formatDate, parseDate } from "src/utils/commons";
import RowStack from "src/layouts/RowStack";
import { RHFDatePicker, RHFTextField } from "src/components/hook-form";
import GamePass from "src/components/GamePass";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "week", label: "Week", alignRight: false },
  { id: "updatedAt", label: "Last Updated", alignRight: false },
  { id: null, label: null, alignRight: false },
];
// ----------------------------------------------------------------------

const tenDigitArray = [1, 2, 3, 4, 5, 6];

export default function WeeklyJodiCharts() {
  const {
    isLoading,
    error,
    data = { data: [] },
  } = useQuery(QUERY_KEYS.WEEKLY_CHART_LIST_JODI, appsApi.getWeeklyJodiChart);

  const gamePassResponse = useQuery(QUERY_KEYS.GAME_PASS, appsApi.getGamePass);

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const isEditMode = typeof openForm == "object";

  const onSuccess = (res, key) => {
    if (res.status === 1) {
      notify.toastSuccess(`Weekly chart ${key} Successully!`);
      setOpenForm(false);
      queryClient.invalidateQueries(QUERY_KEYS.WEEKLY_CHART_LIST_JODI);
      return;
    }
  };

  const createChart = useMutation(appsApi.createWeeklyChart, {
    onSuccess: (res) => onSuccess(res, "Created"),
  });

  const updateChart = useMutation(appsApi.updateWeeklyChart, {
    onSuccess: (res) => onSuccess(res, "Updated"),
  });

  const deleteChart = useMutation(appsApi.deleteWeeklyChart, {
    onSuccess: (res) => onSuccess(res, "Deleted"),
  });

  useEffect(() => {
    setOpenForm(data.data[0]);
  }, [data.data]);

  const onEdit = (item) => {
    setOpenForm(item);
  };

  const onDelete = (item) => deleteChart.mutate(item._id);

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Weekly Jodi Charts
          </Typography>
        </Stack>
        <Card>
          {openForm && (
            <Form
              mode={isEditMode ? "UPDATE" : "CREATE"}
              isSubmitting={
                createChart.isLoading ||
                updateChart.isLoading ||
                deleteChart.isLoading
              }
              openForm={openForm}
              setOpenForm={setOpenForm}
              onUpdate={updateChart}
              onCreate={createChart}
            />
          )}
        </Card>
        <GamePass data={gamePassResponse?.data?.data} />
      </Container>
    </Page>
  );
}

const RowItem = ({ row, selected, onEdit, onDelete }) => {
  const { _id, weekStartDate, weekEndDate, updatedAt } = row;
  const isItemSelected = selected.indexOf(name) !== -1;

  return (
    <TableRow
      hover
      key={_id}
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell>{`${weekStartDate} to ${weekEndDate}`}</TableCell>
      <TableCell align="left">{dayjs(updatedAt).format("lll")}</TableCell>
      <TableCell align="right">
        <MoreMenu onEdit={() => onEdit(row)} onDelete={() => onDelete(row)} />
      </TableCell>
    </TableRow>
  );
};

// ----------------------------------------------------------------------

const Form = (props) => {
  const { openForm, setOpenForm, isSubmitting, mode, onUpdate, onCreate } =
    props;

  const onSubmitChart = (vals) => {
    const params = {
      weekStartDate: formatDate(vals.weekStartDate),
      weekEndDate: formatDate(vals.weekEndDate),
      chartType: "jodi",
    };
    const weekResultData = {};
    delete vals.weekStartDate;
    delete vals.weekEndDate;
    _.map(vals, (v, k) => (weekResultData[k] = v));
    params.weekResultData = weekResultData;

    if (mode == "CREATE") onCreate.mutate(params);
    if (mode == "UPDATE") {
      delete params.result;
      onUpdate.mutate({ params, id: openForm._id });
    }
  };

  let defaultValues = useMemo(() => {
    let defs = {
      weekStartDate: dayjs(),
      weekEndDate: dayjs(),
    };
    tenDigitArray.map((digit) => (defs[`${digit}`] = ""));
    if (mode === "UPDATE") {
      defs.weekStartDate = parseDate(openForm.weekStartDate);
      defs.weekEndDate = parseDate(openForm.weekEndDate);
      if (!_.isEmpty(openForm.weekResultData)) {
        _.map(openForm.weekResultData, (digitString, digitArrayIndex) => {
          defs[`${digitArrayIndex}`] = digitString;
        });
      }
      return defs;
    }
    return defs;
  }, [openForm]);

  const Schema = useMemo(() => {
    const shape = {
      weekStartDate: Yup.string().required("Start date is required"),
      weekEndDate: Yup.string().required("End date is required"),
    };
    tenDigitArray.map(
      (digit) => (shape[`${digit}`] = Yup.string().required("required")),
    );
    return Yup.object().shape(shape);
  }, []);

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  return (
    <FormSidebar
      renderWithoutSideBar
      formTitle={mode === "UPDATE" ? "Update Chart" : "Create Chart"}
      isOpenSidebar={Boolean(openForm)}
      onCloseSidebar={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmitChart}
      isSubmitting={isSubmitting}
    >
      <RowStack>
        <RHFDatePicker name="weekStartDate" label="Week Start Date" />
        <RHFDatePicker name="weekEndDate" label="Week End Date" />
      </RowStack>
      {tenDigitArray.map((digit) => {
        return (
          <Stack
            width="100%"
            direction="row"
            spacing={1}
            alignItems="center"
            key={digit}
          >
            <RHFTextField
              key={`${digit}`}
              name={`${digit}`}
              label={`Results`}
            />
          </Stack>
        );
      })}
    </FormSidebar>
  );
};
