import { useCallback, useEffect, useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
// components
import Page from "../components/Page";
import CalendarView from "src/components/CalendarView";
import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import { getDigitFromPana, parseDate } from "src/utils/commons";
import { RESULT_OPTIONS } from "src/_mock/market";
import {
  RHFAutocomplete,
  RHFDatePicker,
  RHFSelect,
} from "src/components/hook-form";
import { Box } from "@mui/system";

export default function Result() {
  const { data } = useQuery(QUERY_KEYS.MARKET_LIST, appsApi.getMarket);

  const marketOptions = useMemo(() => {
    if (data && data.data && data.data.length > 0) {
      return data.data.map((m) => {
        return {
          label: m.name,
          value: m,
        };
      });
    }
    return [];
  }, [data]);

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const isEditMode = typeof openForm == "object";

  const marketResultMutation = useMutation(appsApi.getMarketResultsChart);

  const onSuccess = (res, key) => {
    if (res.status === 1) {
      notify.toastSuccess(`Result ${key} Successully!`);
      setOpenForm(false);
      queryClient.invalidateQueries(QUERY_KEYS.MARKET_RESULT_CALENDAR);
      marketResultMutation.mutate({
        marketId: marketResultMutation.variables.marketId,
      });
      return;
    }
  };

  const create = useMutation(appsApi.createMarketResults, {
    onSuccess: (res) => onSuccess(res, "Created"),
  });

  const update = useMutation(appsApi.updateMarketResults, {
    onSuccess: (res) => onSuccess(res, "Updated"),
  });

  const onEdit = (item) => {
    setOpenForm(item);
  };

  const handleMarketDropdown = async (e) => {
    setSelectedMarket(e.target.value);
    const foundMarket = data.data.find((m) => m.name === e.target.value);
    marketResultMutation.mutate({
      marketId: foundMarket._id,
    });
  };

  const renderMarketSelect = () => {
    return (
      <>
        <InputLabel
          id={"Market"}
          sx={{
            backgroundColor: "white",
            paddingLeft: 0.5,
            paddingRight: 0.5,
          }}
        >
          Select Market
        </InputLabel>
        <Select
          sx={{ minWidth: 300, mb: 2 }}
          placeholder="Select Market"
          labelId={"Market"}
          value={selectedMarket}
          onChange={handleMarketDropdown}
        >
          {marketOptions.map((op, index) => (
            <MenuItem key={`${op.label}_${index}`} value={op.label}>
              {op.label}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  const handleCalendar = (args, date) => {
    if (args) {
      setSelectedDate(parseDate(args.resultDate));
      onEdit(args);
      return;
    }
    if (date) {
      setSelectedDate(parseDate(date));
      onEdit(true);
    }
  };

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Results
          </Typography>
          {/* <Button
            onClick={() => setOpenForm(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Update Result
          </Button> */}
        </Stack>
        <Card>
          <Box p={4}>
            {renderMarketSelect()}
            {selectedMarket && (
              <CalendarView
                data={
                  (marketResultMutation.data &&
                    marketResultMutation.data.data) ||
                  []
                }
                onClick={handleCalendar}
              />
            )}
          </Box>
        </Card>
        {openForm && (
          <ResultForm
            mode={isEditMode ? "UPDATE" : "CREATE"}
            isSubmitting={update.isLoading || create.isLoading}
            openForm={openForm}
            setOpenForm={setOpenForm}
            onUpdate={update}
            onCreate={create}
            extraProps={{
              marketOptions: marketOptions,
              selectedMarket: selectedMarket,
              selectedDate: selectedDate,
            }}
          />
        )}
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

const ResultForm = (props) => {
  const {
    openForm,
    setOpenForm,
    isSubmitting,
    mode,
    onUpdate,
    onCreate,
    extraProps,
  } = props;

  const ResultSchema = Yup.object().shape({
    market: Yup.string().required("Market name is required"),
    session: Yup.string().required("Session is required"),
    date: Yup.string().required("Date is required"),
    result: Yup.string().required("Result is required"),
  });

  let defaultValues = useMemo(() => {
    if (mode === "UPDATE") {
      return {
        market: extraProps.selectedMarket,
        session: "Open",
        date: extraProps.selectedDate,
        result: "",
      };
    }
    return {
      market: extraProps.selectedMarket,
      session: "Open",
      date: extraProps.selectedDate,
      result: "",
    };
  }, [openForm, extraProps]);

  const methods = useForm({
    resolver: yupResolver(ResultSchema),
    defaultValues,
  });

  const session = useWatch({ control: methods.control, name: "session" });

  useEffect(() => {
    if (mode === "UPDATE") {
      // methods.setValue("market", openForm.marketName);
      methods.setValue("market", extraProps.selectedMarket);
      methods.setValue(
        "result",
        session === "Open" ? openForm.openPana : openForm.closePana,
      );
    }
  }, [session]);

  const onSubmitResult = useCallback(
    (values) => {
      const found = extraProps.marketOptions.find(
        (m) => methods.getValues("market") === m.label,
      );
      if (_.isEmpty(found)) return;
      const m = found.value;
      const params = {
        marketId: m._id,
        marketName: m.name,
        isNotified: false,
      };
      params.resultDate = dayjs(values.date).format("DD/MM/YYYY");

      if (session == "Close") {
        params.closePana = values.result;
        params.closeDigit = getDigitFromPana(values.result);
      }
      if (session == "Open") {
        params.openPana = values.result;
        params.openDigit = getDigitFromPana(values.result);
      }
      if (mode == "CREATE") onCreate.mutate(params);
      if (mode == "UPDATE") {
        params._id = openForm._id;
        onUpdate.mutate({ params, id: openForm._id });
      }
    },
    [session],
  );

  return (
    <FormSidebar
      formTitle={mode === "UPDATE" ? "Update Result" : "Create Result"}
      isOpenSidebar={Boolean(openForm)}
      onCloseSidebar={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmitResult}
      isSubmitting={isSubmitting}
    >
      <RHFSelect
        name="market"
        label="Select Market"
        options={extraProps.marketOptions}
        disabled={true}
      />
      <RHFDatePicker name="date" label="Date" disabled={true} />
      <RHFSelect
        name="session"
        label="Select Session"
        options={[
          { label: "Open", value: "Open" },
          { label: "Close", value: "Close" },
        ]}
      />
      <RHFAutocomplete
        name="result"
        label={`${session} Result`}
        options={RESULT_OPTIONS}
      />
    </FormSidebar>
  );
};
