import _ from "lodash-es";
import { VALID_PANAS } from "src/_mock/numbers";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export const CIPHER_KEY = "myTotallySecretKey";

export const PANA_TYPES = {
  SINGLE: "single",
  DOUBLE: "double",
  TRIPLE: "triple",
  ANY: "any",
};

export const WEEK_DAYS = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

export const TIME_FORMAT = "hh:mmA";

export const getFormatedAddress = (location) => {
  let string = "-";
  if (location && !_.isEmpty(location.city)) {
    string = `${location.city}`;
  }
  return string;
};

export const hasDuplicateDigit = (val = "") => {
  const arr = Array.from(val);
  const set = new Set(arr);
  return arr.length !== set.size;
};

export const getAllPanasOfType = (type) => {
  let allPanas = [];
  _.map(VALID_PANAS, (digits) => {
    if (type == PANA_TYPES.ANY) {
      _.map(digits.single, (pana) => allPanas.push(String(pana)));
      _.map(digits.double, (pana) => allPanas.push(String(pana)));
      _.map(digits.triple, (pana) => allPanas.push(String(pana)));
    } else {
      _.map(digits[type], (pana) => allPanas.push(String(pana)));
    }
  });
  // allPanas = _.sortBy(allPanas, (p) => p);
  return allPanas;
};

export const getJodiDigit = (pana) => {
  pana = String(pana);
  const sum =
    Number(pana.charAt(0)) + Number(pana.charAt(1)) + Number(pana.charAt(2));
  const splited = String(sum).split("");
  return String(splited[splited.length - 1]);
};

export const getCutOffDigit = (digit) => {
  if (Number(digit) == 5) {
    return String(0);
  }
  if (Number(digit) < 5) {
    return String(Number(digit) + 5);
  }
  if (Number(digit) > 5) {
    return String(Number(digit) - 5);
  }
};

export const getDigitFromPana = (pana) => {
  let k;
  _.map(VALID_PANAS, (panaSet, key) => {
    const allPanasOfSet = [
      ...panaSet[PANA_TYPES.SINGLE],
      ...panaSet[PANA_TYPES.DOUBLE],
      ...panaSet[PANA_TYPES.TRIPLE],
    ];

    if (allPanasOfSet.includes(pana)) {
      k = key;
    }
  });
  return k;
};

export const compareTimeStatus = (timings, isOpenTime) => {
  //returns true if the given time is passed means time to show
  if (typeof timings !== "string") return false;

  const string = isOpenTime
    ? extractStartTime(timings)
    : extractEndTime(timings);
  let cdate = dayjs().format("DD/MM/YYYY");
  // let cdate = "22/09/2022"; //! MANUAL TESTING
  let now = dayjs();
  // let now = dayjs(`22/09/2022 12:28AM`, "DD/MM/YYYY hh:mmA"); //! MANUAL TESTING

  // let isNightTime = false;
  // if (
  //   dayjs(`${cdate} ${string}`, "DD/MM/YYYY hh:mmA").isSameOrAfter(
  //     dayjs(`${cdate} 12:00AM`, "DD/MM/YYYY hh:mmA"),
  //   ) &&
  //   dayjs(`${cdate} ${string}`, "DD/MM/YYYY hh:mmA").isSameOrBefore(
  //     dayjs(`${cdate} 04:59AM`, "DD/MM/YYYY hh:mmA"),
  //   )
  // ) {
  //   isNightTime = true;
  // }
  if (string.startsWith("12") && string.endsWith("AM")) {
    // For Close Time
    return (
      now.isSameOrAfter(dayjs(`${cdate} ${string}`, "DD/MM/YYYY hh:mmA")) &&
      now.isSameOrBefore(dayjs(`${cdate} 12:59AM`, "DD/MM/YYYY hh:mmA"))
    );
  }

  //Allow declare results even on late night after 12 AM to 5 AM
  if (
    now.isSameOrAfter(dayjs(`${cdate} 12:00AM`, "DD/MM/YYYY hh:mmA")) &&
    now.isSameOrBefore(dayjs(`${cdate} 04:59AM`, "DD/MM/YYYY hh:mmA"))
  ) {
    cdate = dayjs().subtract(1, "day").format("DD/MM/YYYY");
  }
  return now.isSameOrAfter(dayjs(`${cdate} ${string}`, "DD/MM/YYYY hh:mmA"));
};

export function extractStartTime(fullTimeString) {
  return fullTimeString.split(" ")[0];
}

export function extractEndTime(fullTimeString) {
  return fullTimeString.split(" ")[2];
}

export function getStartTime(string) {
  if (!string) return dayjs(new Date().setHours(9, 0, 0, 0));
  return dayjs(string.split(" ")[0], TIME_FORMAT);
}

export function getEndTime(string) {
  if (!string) return dayjs(new Date().setHours(6, 0, 0, 0));
  return dayjs(string.split(" ")[2], TIME_FORMAT);
}

export function getOpenResult(string) {
  return string.split("-")[0] || "***";
}

export function getCloseResult(string) {
  return string.split("-")[2] || "***";
}

export function getFullResult(obj) {
  let openPana = "***";
  let openDigit = "*";
  let closeDigit = "*";
  let closePana = "***";
  if (_.has(obj, "openPana") && obj.openPana && obj.openPana !== "***") {
    openPana = String(obj.openPana);
    openDigit = String(getDigitFromPana(obj.openPana));
  }
  if (_.has(obj, "closePana") && obj.closePana && obj.closePana !== "***") {
    closePana = String(obj.closePana);
    closeDigit = String(getDigitFromPana(obj.closePana));
  }
  return `${openPana}-${openDigit}${closeDigit}-${closePana}`;
}

export function getFullTimeString(obj) {
  return `${dayjs(obj.startTime).format(TIME_FORMAT)} to ${dayjs(
    obj.endTime,
  ).format(TIME_FORMAT)}`;
}

export const parseDate = (dateStr) => dayjs(dateStr, "DD/MM/YYYY");

export const formatDate = (dayjsObj) => dayjs(dayjsObj).format("DD/MM/YYYY");

export const getWeekDays = (daysArray) => {
  const result = [false, false, false, false, false, false, false];
  daysArray.map((weekday) => (result[weekday.value] = true));
  return result;
};
export const parseWeekDays = (array) => {
  if (_.isEmpty(array)) return [];
  const result = [];
  array.map((weekday, index) => {
    if (weekday) {
      result.push(WEEK_DAYS[index]);
    }
  });
  return result;
};
export const getWeekDaysFormatString = (array) => {
  let result = "";
  let selected = parseWeekDays(array);
  selected.map(
    (s) =>
      (result = result + `${result ? " ," : ""} ${s.label.substring(0, 3)}`),
  );
  return result;
};

export const getCurrentDateInUTC = () => dayjs.utc().format();

//---------------------------- SCRAPING LOGIC ---------------------------------
/*
function getDates() {
  var dateArray = [];
  var currentDate = dayjs("01/01/2018", "DD/MM/YYYY");
  var stopDate = dayjs("30/07/2022", "DD/MM/YYYY");
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format("DD/MM/YYYY"));
    currentDate = dayjs(currentDate).add(1, "days");
  }
  return dateArray;
}

const datesArray = getDates();
const all = getAllPanasOfType(PANA_TYPES.ANY);

// const sample = {
//   marketId: "",
//   marketName: "",
//   resultDate: "",
//   openPana: "",
//   openDigit: "",
//   closePana: "",
//   closeDigit: "",
// };

const MAIN = [
  {
    mid: "62d6ecdf3681562b11b844fc",
    mname: "TIME BAZAR",
  },
  {
    mid: "62d6ec853681562b11b844fb",
    mname: "SRIDEVI",
  },
  {
    mid: "62d6ed313681562b11b844fe",
    mname: "MILAN DAY",
  },
  {
    mid: "62d6ed043681562b11b844fd",
    mname: "RAJDHANI DAY",
  },
];
const DATA = [];

MAIN.map((market) => {
  let mkt = market;
  mkt.dateArray = datesArray.map((d) => {
    const open = all[Math.floor(Math.random() * 219)];
    const close = all[Math.floor(Math.random() * 219)];
    const sortDate = dayjs.utc(d, "DD/MM/YYYY").format();
    return {
      marketId: market.mid,
      marketName: market.mname,
      resultDate: d,
      resultSortDate: sortDate,
      openPana: open,
      openDigit: getDigitFromPana(open),
      closePana: close,
      closeDigit: getDigitFromPana(close),
    };
  });
  DATA.push(mkt);
});

window.data = DATA;

*/
