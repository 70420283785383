// import { useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
// import * as Yup from "yup";
// components
import Page from "../components/Page";
// import Iconify from "../components/Iconify";
import CommonTable from "src/components/CommonTable";
// import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { sentenceCase } from "change-case";
// import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
// import RowStack from "src/layouts/RowStack";
// import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import { format } from "date-fns";
import { useLocation, useParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import { useState } from "react";
import Label from "src/components/Label";
import { useAlert } from "src/hooks/useNotify";
// import { getEndTime, getStartTime } from "src/utils/formatTime";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "deviceId", label: "Device Id", alignRight: false },
  { id: "deviceName", label: "Device Name", alignRight: false },
  { id: "otpCount", label: "Total Otp send", alignRight: false },
  { id: "deviceStatus", label: "User Device Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

export default function DeviceDetails() {
  const { userId } = useParams();
  const { state } = useLocation();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedDeviceData, setSelectedDeviceData] = useState("");
  const [deviceList, setDeviceList] = useState(state.deviceData);
  const notify = useAlert();

  const deviceBlockUnBlockMutation = useMutation(appsApi.deviceBlockUnBlock, {
    onSuccess: (res) => {
      if (res.status === 1) {
        notify.toastSuccess(`Device status updated Successully!`);
        handleClose();
        setDeviceList(res.user.deviceData);
        window.location.deviceData = res.user.deviceData;
      }
    },
  });

  const handleClose = () => setOpenDeleteConfirm(false);
  const onBlockUnblock = () => {
    const params = selectedDeviceData;
    deviceBlockUnBlockMutation.mutate({ params, userId });
    setOpenDeleteConfirm(false);
  };

  const handleBlockUnBlock = (deviceId, isBlocked) => {
    setOpenDeleteConfirm(true);
    setSelectedDeviceData({ deviceId, isBlocked });
  };

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            User Devices
          </Typography>
        </Stack>
        <Card>
          <CommonTable
            loading={false}
            tableTitle="Logs"
            tableHead={TABLE_HEAD}
            list={deviceList || []}
            RowItem={(params) => (
              <RowItem {...params} handleBlockUnBlock={handleBlockUnBlock} />
            )}
          />
        </Card>
        <Dialog
          open={openDeleteConfirm}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {!selectedDeviceData.isBlocked
              ? "Unblock this device"
              : "Block this device"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to{" "}
              {!selectedDeviceData.isBlocked ? "Unblock" : "Block"} this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={onBlockUnblock}>Yes</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

const RowItem = ({ row, selected, handleBlockUnBlock }) => {
  const { deviceId, deviceName, otpCount } = row;
  const isBlocked = otpCount === 3;
  return (
    <TableRow key={deviceId}>
      <TableCell component="th" scope="row">
        {deviceId}
      </TableCell>
      <TableCell component="th" scope="row">
        {deviceName}
      </TableCell>
      <TableCell component="th" scope="row">
        {otpCount}
      </TableCell>
      <TableCell>
        <Label variant="ghost" color={isBlocked ? "error" : "success"}>
          {sentenceCase(isBlocked ? "Blocked" : "Not Blocked")}
        </Label>
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title={`${!isBlocked ? "Block" : "UnBlock"}`}>
            <IconButton
              onClick={() => {
                handleBlockUnBlock(deviceId, !isBlocked);
              }}
            >
              <Iconify
                icon={isBlocked ? "ooui:un-block" : "ooui:block"}
                width={28}
                height={28}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
