import { useMemo, useRef, useState } from "react";
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableRow,
  TableCell,
  Box,
  Chip,
} from "@mui/material";
import * as Yup from "yup";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// components
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import CommonTable from "src/components/CommonTable";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi, { API_BASE_URL } from "src/api/api";
import { RHFTextField } from "src/components/hook-form";
import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import { format } from "date-fns";
import { useAuth } from "src/utils/AuthContext";
import RowStack from "src/layouts/RowStack";
import dayjs from "dayjs";
import shadows from "@mui/material/styles/shadows";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
  { id: "createdAt", label: "Created", alignRight: false },
  { id: "updatedAt", label: "Last Updated", alignRight: false },
];

// ----------------------------------------------------------------------

export default function Notification() {
  const { userData } = useAuth();
  const uid = userData._id;
  const { isLoading, data = { data: [] } } = useQuery(
    [QUERY_KEYS.NOTIFICATION_LIST, uid],
    () => appsApi.getNotification(uid),
  );

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const isEditMode = typeof openForm == "object";

  const onSuccess = (res, key) => {
    if (res.status === 1) {
      notify.toastSuccess(`Notification ${key} Successully!`);
      setOpenForm(false);
      queryClient.invalidateQueries(QUERY_KEYS.NOTIFICATION_LIST);
      return;
    }
  };

  const createNotification = useMutation(appsApi.createNotification, {
    onSuccess: (res) => onSuccess(res, "Created"),
  });
  const createNotificationWithMedia = useMutation(
    appsApi.createNotificationWithMedia,
    {
      onSuccess: (res) => onSuccess(res, "Created"),
    },
  );

  const updateNotification = useMutation(appsApi.updateNotification, {
    onSuccess: (res) => onSuccess(res, "Updated"),
  });

  const deleteNotification = useMutation(appsApi.deleteNotification, {
    onSuccess: (res) => onSuccess(res, "Deleted"),
  });

  const onEdit = (item) => {
    setOpenForm(item);
  };

  const onDelete = (item) => deleteNotification.mutate(item._id);

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Notification
          </Typography>
          <Button
            onClick={() => setOpenForm(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Notification
          </Button>
        </Stack>
        <Card>
          <CommonTable
            loading={isLoading}
            tableTitle="Notification"
            tableHead={TABLE_HEAD}
            list={data.data}
            RowItem={(params) => (
              <RowItem {...params} onEdit={onEdit} onDelete={onDelete} />
            )}
          />
        </Card>
        {openForm && (
          <NotificationForm
            mode={isEditMode ? "UPDATE" : "CREATE"}
            isSubmitting={
              createNotification.isLoading ||
              createNotificationWithMedia.isLoading ||
              updateNotification.isLoading ||
              deleteNotification.isLoading
            }
            openForm={openForm}
            setOpenForm={setOpenForm}
            onUpdate={updateNotification}
            onCreate={createNotification}
            onCreateWithMedia={createNotificationWithMedia}
          />
        )}
      </Container>
    </Page>
  );
}

const RowItem = ({ row, selected, onEdit, onDelete }) => {
  const { _id, title, message, createdAt, updatedAt } = row;
  const isItemSelected = selected.indexOf(name) !== -1;
  const media = row.notificationMedia ? row.notificationMedia : false;
  const isVideoType =
    media &&
    (media.endsWith(".mp4") ||
      media.endsWith(".mov") ||
      media.endsWith(".webm"));

  return (
    <TableRow
      hover
      key={_id}
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell>
        {media && !isVideoType && (
          <img
            style={{
              height: 80,
              width: 80,
              borderRadius: 10,
              boxShadow: shadows[4],
            }}
            src={`${API_BASE_URL}user/read-image/${media}`}
            crossOrigin="anonymous"
          />
        )}
        {media && isVideoType && (
          <div className="player-wrapper">
            <video
              style={{
                height: 94,
                width: 168,
                borderRadius: 10,
                boxShadow: shadows[4],
              }}
              controls
              crossOrigin="anonymous"
            >
              <source
                src={`${API_BASE_URL}user/read-image/${media}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {!media && title}
      </TableCell>
      <TableCell align="left">{message}</TableCell>
      <TableCell align="left">
        {dayjs(createdAt).format("DD/MM/YYYY hh:mm A")}
      </TableCell>
      <TableCell align="left">
        {dayjs(updatedAt).format("DD/MM/YYYY hh:mm A")}
      </TableCell>
      <TableCell align="right">
        <MoreMenu onDelete={() => onDelete(row)} />
      </TableCell>
    </TableRow>
  );
};

// ----------------------------------------------------------------------

const NotificationForm = (props) => {
  const {
    openForm,
    setOpenForm,
    isSubmitting,
    mode,
    onUpdate,
    onCreate,
    onCreateWithMedia,
  } = props;
  const [mediaFile, setMediaFile] = useState("");
  const fileInputRef = useRef();

  const onSubmitNotification = (vals) => {
    const params = {
      title: vals.title,
      message: vals.message,
    };
    if (mode == "CREATE") {
      if (mediaFile) {
        var fd = new FormData();
        fd.append("title", params.title || " ");
        fd.append("message", params.message || " ");
        fd.append("file", mediaFile);
        onCreateWithMedia.mutate(fd);
      } else {
        onCreate.mutate(params);
      }
    }
    if (mode == "UPDATE") {
      delete params.result;
      onUpdate.mutate({ params, id: openForm._id });
    }
  };

  let defaultValues = useMemo(() => {
    if (mode === "UPDATE") {
      return {
        title: openForm.title,
        message: openForm.message,
      };
    }
    return {
      title: "",
      message: "",
    };
  }, [openForm]);

  const NotificationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string().max(100, "Too long!"),
      message: Yup.string().max(200, "Too long!"),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(NotificationSchema),
    defaultValues,
  });

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setMediaFile(file);
    }
  };

  return (
    <FormSidebar
      formTitle={
        mode === "UPDATE" ? "Update Notification" : "Create Notification"
      }
      isOpenSidebar={Boolean(openForm)}
      onCloseSidebar={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmitNotification}
      isSubmitting={isSubmitting}
    >
      <RHFTextField name="title" label="Title" />
      <RHFTextField
        name="message"
        label="Message"
        multiline
        maxRows={8}
        minRows={8}
      />
      {mediaFile ? (
        <Box>
          <Chip
            color="primary"
            label={mediaFile.name || "file"}
            onDelete={() => setMediaFile("")}
          />
        </Box>
      ) : (
        <Stack direction={"row"}>
          <Button
            variant="outlined"
            onClick={() => fileInputRef.current.click()}
          >
            Upload
          </Button>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg, video/*"
            ref={fileInputRef}
            onChange={onFileChange}
            style={{ display: "none" }}
          />
        </Stack>
      )}
    </FormSidebar>
  );
};
