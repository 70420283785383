// import { useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
// import * as Yup from "yup";
// components
import Page from "../components/Page";
// import Iconify from "../components/Iconify";
import CommonTable from "src/components/CommonTable";
// import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { sentenceCase } from "change-case";
// import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
// import RowStack from "src/layouts/RowStack";
// import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
// import { getEndTime, getStartTime } from "src/utils/formatTime";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "logType", label: "Action", alignRight: false },
  { id: "ip", label: "IP Address", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Updated At", alignRight: false },
];

// ----------------------------------------------------------------------

export default function Logs() {
  const { userId } = useParams();
  const {
    isLoading,
    error,
    data = { data: [] },
  } = useQuery([QUERY_KEYS.LOG, userId], () => appsApi.getLogs(userId), {
    enabled: userId && userId.length === 24,
  });
  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Logs
          </Typography>
        </Stack>
        <Card>
          <CommonTable
            loading={isLoading}
            tableTitle="Logs"
            tableHead={TABLE_HEAD}
            list={data.data || []}
            RowItem={(params) => <RowItem {...params} />}
          />
        </Card>
      </Container>
    </Page>
  );
}

const RowItem = ({ row, selected }) => {
  const { _id, logType, updatedAt, createdAt, rawHeaders } = row;
  return (
    <TableRow key={_id}>
      <TableCell component="th" scope="row">
        {sentenceCase(logType)}
      </TableCell>
      <TableCell component="th" scope="row">
        {JSON.parse(row.logData).rawHeaders[7]}
      </TableCell>
      <TableCell align="left">
        {format(new Date(createdAt), "MMMM dd, yyyy'")}
      </TableCell>
      <TableCell align="left">
        {updatedAt ? format(new Date(updatedAt), "MMMM dd, yyyy'") : "-"}
      </TableCell>
    </TableRow>
  );
};
