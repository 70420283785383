// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "user",
    path: "/user",
    icon: getIcon("eva:people-fill"),
  },
  {
    title: "market",
    path: "/market",
    icon: getIcon("healthicons:market-stall"),
  },
  {
    title: "Market Setting",
    path: "/market-setting",
    icon: getIcon("ant-design:setting-filled"),
  },
  {
    title: "results",
    path: "/result",
    icon: getIcon("carbon:result"),
  },
  {
    title: "Weekly Charts",
    icon: getIcon("ant-design:bar-chart-outlined"),
    children: [
      {
        title: "Weekly Patti",
        path: "/weekly-patti",
        icon: getIcon("ant-design:bar-chart-outlined"),
      },
      {
        title: "Weekly Jodi",
        path: "/weekly-jodi",
        icon: getIcon("ant-design:bar-chart-outlined"),
      },
      {
        title: "Weekly Open or Close",
        path: "/weekly-open-close",
        icon: getIcon("ant-design:bar-chart-outlined"),
      },
    ],
  },
  {
    title: "news",
    path: "/news",
    icon: getIcon("typcn:news"),
  },
  {
    title: "notification",
    path: "/notification",
    icon: getIcon("bxs:bell"),
  },
];

export default navConfig;
