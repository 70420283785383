import { useEffect, useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
// components
import Page from "../components/Page";
import CommonTable from "src/components/CommonTable";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import _ from "lodash";
import { RHFSwitch, RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/Iconify";
import Label from "src/components/Label";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "newsText", label: "News", alignRight: false },
  { id: "newsDuration", label: "Display Duration(min)", alignRight: false },
  { id: "updatedAt", label: "Updated", alignRight: false },
  { id: null, label: null, alignRight: false },
];
// ----------------------------------------------------------------------

const DEFAULT_NEWS_DURATION = 30;

export default function News() {
  const {
    isLoading,
    error,
    data = { data: [] },
  } = useQuery(QUERY_KEYS.NEWS_LIST, appsApi.getNews);

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const isEditMode = typeof openForm == "object";

  const onSuccess = (res, key) => {
    if (res.status === 1) {
      notify.toastSuccess(`News ${key} Successully!`);
      setOpenForm(false);
      queryClient.invalidateQueries(QUERY_KEYS.NEWS_LIST);
      return;
    }
  };

  const createNews = useMutation(appsApi.createNews, {
    onSuccess: (res) => onSuccess(res, "Created"),
  });

  const updateNews = useMutation(appsApi.updateNews, {
    onSuccess: (res) => onSuccess(res, "Updated"),
  });

  const deleteNews = useMutation(appsApi.deleteNews, {
    onSuccess: (res) => onSuccess(res, "Deleted"),
  });

  const onDelete = (item) => deleteNews.mutate(item._id);

  const onEdit = (item) => {
    setOpenForm(item);
  };

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          mt={5}
        >
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
          <Button
            onClick={() => setOpenForm(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add News
          </Button>
        </Stack>
        <Card>
          <CommonTable
            loading={isLoading}
            tableTitle="News"
            tableHead={TABLE_HEAD}
            list={data.data || []}
            RowItem={(params) => (
              <RowItem {...params} onEdit={onEdit} onDelete={onDelete} />
            )}
          />
        </Card>
        {openForm && (
          <Form
            isSubmitting={
              createNews.isLoading ||
              updateNews.isLoading ||
              deleteNews.isLoading
            }
            mode={isEditMode ? "UPDATE" : "CREATE"}
            openForm={openForm}
            setOpenForm={setOpenForm}
            onUpdate={updateNews}
            onDelete={deleteNews}
            onCreate={createNews}
          />
        )}
      </Container>
    </Page>
  );
}

const RowItem = ({ row, onEdit, onDelete }) => {
  const {
    _id,
    isShowed,
    newsText = "",
    newsDuration = "",
    isDefault,
    updatedAt,
  } = row;
  return (
    <TableRow hover key={_id}>
      <TableCell align="left" sx={{ minWidth: 300 }}>
        {newsText || "-"}
      </TableCell>
      <TableCell align="left">
        {isDefault ? "Default " : newsDuration || "-"}
      </TableCell>
      <TableCell align="left">
        <Label variant="ghost" color={!isShowed ? "error" : "success"}>
          {!isShowed ? "No" : "Yes"}
        </Label>
      </TableCell>
      <TableCell align="left">{dayjs(updatedAt).format("lll")}</TableCell>
      <TableCell align="right">
        <MoreMenu onEdit={() => onEdit(row)} onDelete={() => onDelete(row)} />
      </TableCell>
    </TableRow>
  );
};

// ----------------------------------------------------------------------

const Form = (props) => {
  const { openForm, setOpenForm, isSubmitting, mode, onUpdate, onCreate } =
    props;
  const ResultSchema = Yup.object().shape({
    newsText: Yup.string()
      .required("News Text is Required")
      .min(5, "Too short!")
      .max(1000, "Too long!"),
    newsDuration: Yup.number()
      .typeError("Duration must be a number in minutes")
      .min(1, "Duration must be of atleast 1 minute")
      .required(),
    isDefault: Yup.bool(),
  });

  let defaultValues = useMemo(() => {
    if (mode === "UPDATE") {
      return {
        newsText: openForm.newsText || "",
        newsDuration: openForm.newsDuration || DEFAULT_NEWS_DURATION,
        isDefault: openForm.isDefault || false,
      };
    }
    return {
      newsText: "",
      newsDuration: DEFAULT_NEWS_DURATION,
      isDefault: false,
    };
  }, [openForm]);

  const methods = useForm({
    resolver: yupResolver(ResultSchema),
    defaultValues,
  });

  const onSubmitNews = (values) => {
    const params = {
      newsText: values.newsText || "",
      newsDuration: String(values.newsDuration) || "",
      isDefault: values.isDefault || false,
    };
    if (mode == "CREATE") onCreate.mutate(params);
    if (mode == "UPDATE") {
      onUpdate.mutate({ params, id: openForm._id });
    }
  };

  const isDefault = useWatch({ control: methods.control, name: "isDefault" });

  return (
    <FormSidebar
      formTitle={mode === "UPDATE" ? "Update News" : "Create News"}
      isOpenSidebar={Boolean(openForm)}
      onCloseSidebar={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmitNews}
      isSubmitting={isSubmitting}
    >
      <RHFTextField name="newsText" label="News Text" />
      <RHFSwitch name="isDefault" label="Is Default Text" />
      {!isDefault && (
        <RHFTextField
          name="newsDuration"
          label="News Duration(min)"
          type="number"
        />
      )}
    </FormSidebar>
  );
};
