import { Card, Divider, Stack, Typography, colors } from "@mui/material";
import Iconify from "./Iconify";
import { parseDate } from "src/utils/commons";

export default function GamePass({ data }) {
  return (
    <>
      {data && data.length && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4}
            mt={8}
          >
            <Typography variant="h4" gutterBottom>
              Weekly Game Pass
            </Typography>
          </Stack>
          <Card sx={{ p: 6 }}>
            {data.map((section, i, a) => {
              return (
                <>
                  <Typography variant="h5" mb={2}>
                    {parseDate(section.date).format("DD/MM/YYYY, dddd")}
                  </Typography>

                  {section.text.map((value, i) => {
                    return (
                      <Typography
                        variant="body1"
                        key={i}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 0.5,
                        }}
                      >
                        <Iconify
                          icon={"ic:sharp-star-rate"}
                          width={22}
                          height={22}
                          color={colors.yellow[700]}
                          sx={{ mr: 1 }}
                        />
                        {value}
                      </Typography>
                    );
                  })}
                  {a.length - 1 != i && <Divider sx={{ my: 3 }} />}
                </>
              );
            })}
          </Card>
        </>
      )}
    </>
  );
}
