import Axios from "axios";
import _ from "lodash-es";
import { store } from "src/redux/store/store";
import { notiRef } from "src/hooks/useNotify";
import actions from "src/redux/actions/actions";
import { BASE_URL } from "src/config";

export const API_BASE_URL = BASE_URL;

//Endpoints
const signIn = "auth/signin";
const market = "market";
const user = "user";
const getDashboardData = "dashboard/getDashboardData";
const marketResults = "market-result";
const getMarketResultsChart = "market-result/getChartData";
const notification = "notification";
const sendNotificationWithoutMedia = "notification/withOutMedia";
const sendNotificationWithMedia = "notification/withMedia";
const changeMarketOrder = "market/changeMarketOrder";
const news = "news";
const weeklycharts = "weekly-chart";
const log = "log";
const decryptPhone = "user/decryprt-number";
const deviceBlockUnBlock = "user/deviceBlockUnBlock";
const declareHoliday = "market/declareHoliday";
const userBlockUnBlock = "user/userBlockUnBlock";
const getGamePass = "market/getGamePass";

class AppsApi {
  axios;
  getApi;
  postApi;
  postFormDataApi;
  patchApi;
  deleteApi;
  baseUrl;
  handleError;
  handleResponse;
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.axios = Axios.create({
      baseURL: API_BASE_URL,
      timeout: 15000,
      validateStatus: (status) => true,
    });

    this.handleResponse = (response, url, data) => {
      const res = response.data;
      if (res.status && res.status === 1) {
        return res;
      }
      if (res.statusCode === 401) {
        store.dispatch(actions.logout());
        notiRef?.current?.toastError(
          res.message || "Oops! You are Unauthorized!",
        );
        return;
      }
      if (res.message && Array.isArray(res.message)) {
        notiRef?.current?.toastError(
          res.message[0] || "Oops! Something went wrong!",
        );
        return;
      }
      if (res.message && typeof res.message === "string") {
        notiRef?.current?.toastError(res.message);
        return;
      }
      throw res;
    };

    this.handleError = (e) => {
      console.error(e);
      let toastMessage = "Something went wrong!";
      if (e.code === "ECONNABORTED") {
        toastMessage =
          "Request timeout! please check your internet connection.";
      }

      notiRef?.current?.toastError(toastMessage);
    };

    this.initSetup();
  }

  initSetup = () => {
    this.getApi = (url) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .get(url)
        .then((res) => this.handleResponse(res, url))
        .catch(this.handleError);
    };

    this.postApi = (url, data) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      const axiosConfig = {};
      if (url.includes(sendNotificationWithMedia)) {
        axiosConfig.headers = {
          "Content-Type": "multipart/form-data",
        };
      }
      return this.axios
        .post(url, data, axiosConfig)
        .then((res) => this.handleResponse(res, url, data))
        .catch(this.handleError);
    };

    this.patchApi = (url, data) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .patch(url, data)
        .then((res) => this.handleResponse(res, url, data))
        .catch(this.handleError);
    };

    this.deleteApi = (url) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .delete(url)
        .then((res) => this.handleResponse(res, url))
        .catch((e) => console.error(e));
    };
  };

  // All Endpoints
  signIn = (params) => this.postApi(signIn, params);
  getLogs = (userId) => this.getApi(`${log}/${userId}`);

  getDashboardData = () => this.getApi(getDashboardData);

  getMarket = () => this.getApi(market);
  createMarket = (params) => this.postApi(market, params);
  updateMarket = ({ params, id }) => this.patchApi(`${market}/${id}`, params);
  deleteMarket = (id) => this.deleteApi(`${market}/${id}`);
  changeMarketOrder = (params) => this.postApi(changeMarketOrder, params);

  getNews = () => this.getApi(news);
  createNews = (params) => this.postApi(news, params);
  updateNews = ({ params, id }) => this.patchApi(`${news}/${id}`, params);
  deleteNews = (id) => this.deleteApi(`${news}/${id}`);

  getUsers = ({ page, limit }) =>
    this.getApi(user + `?page=${page}&limit=${limit}`);

  getMarketResults = () => this.getApi(marketResults);
  getMarketResultsChart = (params) =>
    this.postApi(getMarketResultsChart, params);
  createMarketResults = (params) => this.postApi(marketResults, params);
  updateMarketResults = ({ params }) =>
    this.patchApi(`${marketResults}`, params);
  deleteMarketResults = (id) => this.deleteApi(`${marketResults}/${id}`);

  getNotification = (id) => this.getApi(`${notification}/${id}`);
  createNotification = (params) =>
    this.postApi(sendNotificationWithoutMedia, params);
  createNotificationWithMedia = (params) =>
    this.postApi(sendNotificationWithMedia, params);

  updateNotification = ({ params, id }) =>
    this.patchApi(`${notification}/${id}`, params);
  deleteNotification = (id) => this.deleteApi(`${notification}/${id}`);

  getWeeklyPanaChart = () => this.getApi(`${weeklycharts}/pana`);
  getWeeklyJodiChart = () => this.getApi(`${weeklycharts}/jodi`);
  getWeeklyOpenCloseChart = () => this.getApi(`${weeklycharts}/open`);
  createWeeklyChart = (params) => this.postApi(weeklycharts, params);
  updateWeeklyChart = ({ params, id }) =>
    this.patchApi(`${weeklycharts}/${id}`, params);
  deleteWeeklyChart = (id) => this.deleteApi(`${weeklycharts}/${id}`);

  getGamePass = () => this.getApi(getGamePass);

  decryptPhone = (params) => this.postApi(decryptPhone, params);
  deviceBlockUnBlock = ({ params, userId }) =>
    this.patchApi(`${deviceBlockUnBlock}/${userId}`, params);
  declareHoliday = ({ params, marketId }) =>
    this.patchApi(`${declareHoliday}/${marketId}`, params);

  userBlockUnBlock = ({ params, userId }) =>
    this.patchApi(`${userBlockUnBlock}/${userId}`, params);
}

const appsApi = new AppsApi(API_BASE_URL);
export default appsApi;
