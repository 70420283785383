import { useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TableRow,
  TableCell,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
// components
import Page from "../components/Page";
import CommonTable from "src/components/CommonTable";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import _ from "lodash";
import { RHFTextField } from "src/components/hook-form";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "name", label: "Market Name", alignRight: false },
  {
    id: "preDeclareLoaderDuration",
    label: "Result Loader Duration",
    alignRight: false,
  },
  {
    id: "postDeclareResultDuration",
    label: "Result Display Duration",
    alignRight: false,
  },
  { id: null },
];
// ----------------------------------------------------------------------

export default function MarketSettings() {
  const {
    isLoading,
    error,
    data = { data: [] },
  } = useQuery(QUERY_KEYS.MARKET_LIST, appsApi.getMarket);

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const isEditMode = typeof openForm == "object";

  const update = useMutation(appsApi.updateMarket, {
    onSuccess: (res) => {
      if (res.status === 1) {
        notify.toastSuccess(`Market settings updated successully!`);
        setOpenForm(false);
        queryClient.invalidateQueries(QUERY_KEYS.MARKET_LIST);
        return;
      }
    },
  });

  const onEdit = (item) => setOpenForm(item);

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Market Setting
          </Typography>
        </Stack>
        <Card>
          <CommonTable
            loading={isLoading}
            tableTitle="Market Setting"
            tableHead={TABLE_HEAD}
            list={data.data || []}
            RowItem={(params) => <RowItem {...params} onEdit={onEdit} />}
            disablePagination={true}
          />
        </Card>
        {openForm && (
          <MarketForm
            isSubmitting={update.isLoading}
            openForm={openForm}
            setOpenForm={setOpenForm}
            onUpdate={update}
            mode={isEditMode ? "UPDATE" : "CREATE"}
          />
        )}
      </Container>
    </Page>
  );
}

const RowItem = ({ row, onEdit }) => {
  const { _id, name, preDeclareLoaderDuration, postDeclareResultDuration } =
    row;
  return (
    <TableRow hover key={_id}>
      <TableCell>{name}</TableCell>
      <TableCell align="left">{preDeclareLoaderDuration}</TableCell>
      <TableCell align="left">{postDeclareResultDuration}</TableCell>
      <TableCell align="right">
        <MoreMenu onEdit={() => onEdit(row)} />
      </TableCell>
    </TableRow>
  );
};

// ----------------------------------------------------------------------

const MarketForm = (props) => {
  const { openForm, setOpenForm, isSubmitting, onUpdate } = props;

  const onSubmitMarket = (vals) => {
    const params = {
      preDeclareLoaderDuration: vals.preDeclareLoaderDuration,
      postDeclareResultDuration: vals.postDeclareResultDuration,
    };
    onUpdate.mutate({ params, id: openForm._id });
  };

  let defaultValues = useMemo(() => {
    return {
      preDeclareLoaderDuration: openForm.preDeclareLoaderDuration,
      postDeclareResultDuration: openForm.postDeclareResultDuration,
    };
  }, [openForm]);

  const MarketSchema = useMemo(() => {
    let validatorSchema = {
      preDeclareLoaderDuration: Yup.number()
        .typeError("Duration must be a number in minutes")
        .required("Required")
        .min(1, "Duration should be of atleast one minute")
        .max(1439, "Duration should should not be longer than one day"),
      postDeclareResultDuration: Yup.number()
        .typeError("Duration must be a number in minutes")
        .required("Required")
        .min(1, "Duration should be of atleast one minute")
        .max(1439, "Duration should should not be longer than one day"),
    };
    return Yup.object().shape(validatorSchema);
  }, []);

  const methods = useForm({
    resolver: yupResolver(MarketSchema),
    defaultValues,
  });

  return (
    <FormSidebar
      formTitle={`${openForm.name}`}
      isOpenSidebar={Boolean(openForm)}
      onCloseSidebar={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmitMarket}
      isSubmitting={isSubmitting}
    >
      <RHFTextField
        name="preDeclareLoaderDuration"
        label="Result Loader Duration (minutes)"
      />
      <RHFTextField
        name="postDeclareResultDuration"
        label="Result Display Duration (minutes)"
      />
    </FormSidebar>
  );
};
