export const VALID_DIGITS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const VALID_PANAS = {
  0: {
    single: [
      "127",
      "136",
      "145",
      "190",
      "235",
      "280",
      "370",
      "389",
      "460",
      "479",
      "569",
      "578",
    ],
    double: ["118", "226", "244", "299", "334", "488", "550", "668", "677"],
    triple: ["000"],
  },
  1: {
    single: [
      "128",
      "137",
      "146",
      "236",
      "245",
      "290",
      "380",
      "470",
      "489",
      "560",
      "579",
      "678",
    ],
    double: ["100", "119", "155", "227", "335", "344", "399", "588", "669"],
    triple: ["777"],
  },
  2: {
    single: [
      "129",
      "138",
      "147",
      "156",
      "237",
      "246",
      "345",
      "390",
      "480",
      "570",
      "589",
      "679",
    ],
    double: ["110", "200", "228", "255", "336", "499", "660", "688", "778"],
    triple: ["444"],
  },
  3: {
    single: [
      "120",
      "139",
      "148",
      "157",
      "238",
      "247",
      "256",
      "346",
      "490",
      "580",
      "670",
      "689",
    ],
    double: ["166", "229", "300", "337", "355", "445", "599", "779", "788"],
    triple: ["111"],
  },
  4: {
    single: [
      "130",
      "149",
      "158",
      "167",
      "239",
      "248",
      "257",
      "347",
      "356",
      "590",
      "680",
      "789",
    ],
    double: ["112", "220", "266", "338", "400", "446", "455", "699", "770"],
    triple: ["888"],
  },
  5: {
    single: [
      "140",
      "159",
      "168",
      "230",
      "249",
      "258",
      "267",
      "348",
      "357",
      "456",
      "690",
      "780",
    ],
    double: ["113", "122", "177", "339", "366", "447", "500", "799", "889"],
    triple: ["555"],
  },
  6: {
    single: [
      "123",
      "150",
      "169",
      "178",
      "240",
      "259",
      "268",
      "349",
      "358",
      "367",
      "457",
      "790",
    ],
    double: ["600", "114", "277", "330", "448", "466", "556", "880", "899"],
    triple: ["222"],
  },
  7: {
    single: [
      "124",
      "160",
      "278",
      "179",
      "250",
      "269",
      "340",
      "359",
      "368",
      "458",
      "467",
      "890",
    ],
    double: ["115", "133", "188", "223", "377", "449", "557", "566", "700"],
    triple: ["999"],
  },
  8: {
    single: [
      "125",
      "134",
      "170",
      "189",
      "260",
      "279",
      "350",
      "369",
      "468",
      "378",
      "459",
      "567",
    ],
    double: ["116", "224", "233", "288", "440", "477", "558", "800", "990"],
    triple: ["666"],
  },
  9: {
    single: [
      "126",
      "135",
      "180",
      "234",
      "270",
      "289",
      "360",
      "379",
      "450",
      "469",
      "478",
      "568",
    ],
    double: ["117", "144", "199", "225", "388", "559", "577", "667", "900"],
    triple: ["333"],
  },
};

export const VALID_FULL_RED_BRACKETS = [
  "00",
  "11",
  "22",
  "33",
  "44",
  "55",
  "66",
  "77",
  "88",
  "99",
];

export const VALID_HALF_RED_BRACKETS = [
  "05",
  "16",
  "27",
  "38",
  "49",
  "50",
  "61",
  "72",
  "83",
  "94",
];

export const PANEL_GROUPS = [
  ["111", "116", "166", "666"],
  ["112", "117", "126", "167", "266", "667"],
  ["113", "118", "136", "168", "366", "668"],
  ["114", "119", "146", "169", "466", "669"],
  ["110", "115", "156", "160", "566", "660"],
  ["122", "127", "177", "226", "267", "677"],
  ["123", "128", "137", "178", "236", "268", "367", "678"],
  ["124", "129", "147", "179", "246", "269", "467", "679"],
  ["120", "125", "157", "170", "256", "260", "567", "670"],
  ["133", "138", "188", "336", "368", "688"],
  ["134", "139", "148", "189", "346", "369", "468", "689"],
  ["130", "135", "158", "180", "356", "360", "568", "680"],
  ["144", "149", "199", "446", "469", "699"],
  ["140", "145", "159", "190", "456", "460", "569", "690"],
  ["100", "150", "155", "556", "560", "600"],
  ["222", "227", "277", "777"],
  ["223", "228", "237", "278", "377", "778"],
  ["224", "229", "247", "279", "477", "779"],
  ["220", "225", "257", "270", "577", "770"],
  ["233", "238", "288", "337", "378", "788"],
  ["234", "239", "248", "289", "347", "379", "478", "789"],
  ["230", "235", "258", "280", "357", "370", "578", "780"],
  ["244", "249", "299", "447", "479", "799"],
  ["240", "245", "259", "290", "457", "470", "579", "790"],
  ["200", "250", "255", "557", "570", "700"],
  ["333", "338", "388", "888"],
  ["334", "339", "348", "389", "488", "889"],
  ["330", "335", "358", "380", "588", "880"],
  ["344", "349", "399", "448", "489", "899"],
  ["340", "345", "359", "390", "458", "480", "589", "890"],
  ["300", "350", "355", "558", "580", "800"],
  ["444", "449", "499", "999"],
  ["440", "445", "459", "490", "599", "990"],
  ["400", "450", "455", "559", "590", "900"],
  ["000", "500", "550", "555"],
];

export const JODI_GROUPS = [
  ["12", "17", "21", "26", "62", "67", "71", "76"],
  ["13", "18", "31", "36", "63", "68", "81", "86"],
  ["14", "19", "41", "46", "64", "69", "91", "96"],
  ["01", "06", "10", "15", "51", "56", "60", "65"],
  ["23", "28", "32", "37", "73", "78", "82", "87"],
  ["24", "29", "42", "47", "74", "79", "92", "97"],
  ["02", "07", "20", "25", "52", "57", "70", "75"],
  ["34", "39", "43", "48", "84", "89", "93", "98"],
  ["03", "08", "30", "35", "53", "58", "80", "85"],
  ["04", "09", "40", "45", "54", "59", "90", "95"],
  ["05", "16", "27", "38", "49", "50", "61", "72", "83", "94"],
  ["00", "11", "22", "33", "44", "55", "66", "77", "88", "99"],
];

export const VALID_JODIS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
];
