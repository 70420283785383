import {
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { compareTimeStatus } from "src/utils/commons";
import Iconify from "./Iconify";
import Label from "./Label";
// import Prompt from "./Prompt";

export default function MemoizedResultInput(props) {
  const theme = useTheme();
  const { resultsMap, setResultsMap, onSubmitResult, isOpen, item } = props;
  const id = `${isOpen ? "open" : "close"}_${item._id}`;
  const originalResult = item.result.split("-")[isOpen ? 0 : 2] || "";
  const isEditMode = isOpen
    ? item.isOpenResultDeclared
    : item.isCloseResultDeclared;

  const value = resultsMap[id] || "";

  const ref = useRef();
  // const confirmRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [notiOption, setNotiOption] = useState("with");

  // const isReadyToDeclare = isOpen
  //   ? item.isOpenTimeStarted
  //   : item.isCloseTimeStarted;

  const [isReadyToDeclare, setReadyToDeclare] = useState(
    compareTimeStatus(item.timings, isOpen),
  );

  useEffect(() => {
    const inter = setInterval(() => {
      const status = compareTimeStatus(item.timings, isOpen);
      if (status) {
        setReadyToDeclare(true);
        clearInterval(inter);
      }
    }, 6000);
    return () => clearInterval(inter);
  }, []);

  // const handleOptions = (opt) => {
  //   onSubmitResult(id, item, isOpen, opt, isEditMode);
  //   setMenuOpen(false);
  // };

  const handleRadio = (e) => setNotiOption(e.target.value);

  const handleOpen = () => {
    //Reset orignial value:
    resultsMap[id] = isEditMode ? originalResult : "";
    setResultsMap({ ...resultsMap });
    setMenuOpen(true);
    setNotiOption("with");
  };

  const handleSubmit = () => {
    const withNotification = !isEditMode ? true : notiOption === "with";
    onSubmitResult(id, item, isOpen, withNotification, isEditMode);
    setMenuOpen(false);
  };

  // const hanldeEnter = () => {
  //   if (!isEditMode) return handleOptions(true);
  //   if (confirmRef.current) {
  //     confirmRef.current.show();
  //   }
  // };

  const memoizeComponent = useMemo(() => {
    return (
      <Stack direction="row">
        <TextField
          sx={[
            {
              width: "7rem",
            },
            !value && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.success.main} !important`,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.success.main} !important`,
              },
            },
          ]}
          disabled={isEditMode}
          size="small"
          value={isEditMode ? originalResult : value}
          onChange={(e) => {
            if (new RegExp("^[0-9]{0,3}$").test(e.target.value))
              resultsMap[id] = e.target.value;
            setResultsMap({ ...resultsMap });
          }}
          onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
        />
        {isEditMode && (
          <IconButton onClick={handleOpen} ref={ref}>
            <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
          </IconButton>
        )}
        <Popover
          open={menuOpen}
          anchorEl={ref.current}
          onClose={() => setMenuOpen(false)}
          PaperProps={{
            sx: { minWidth: 100, maxWidth: "100%", p: 2 },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Stack direction="column" spacing={1.6}>
            <Typography variant="h6">
              {isEditMode ? "Edit" : "Declare"} Result
            </Typography>
            <TextField
              sx={[
                !value && {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.success.main} !important`,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.success.main} !important`,
                  },
                },
              ]}
              size="small"
              value={value}
              onChange={(e) => {
                if (value.length > e.target.value.length) {
                  //Dont check regex when erasing text
                  resultsMap[id] = e.target.value;
                }
                if (new RegExp("^[0-9]{0,3}$").test(e.target.value))
                  resultsMap[id] = e.target.value;
                setResultsMap({ ...resultsMap });
              }}
              // onKeyDown={(e) => (e.key === "Enter" ? hanldeEnter() : null)}
            />
            {isEditMode && (
              <RadioGroup
                aria-labelledby="radio-group"
                value={notiOption}
                onChange={handleRadio}
              >
                <FormControlLabel
                  value="with"
                  control={<Radio />}
                  label="With Notification"
                />
                <FormControlLabel
                  value="without"
                  control={<Radio />}
                  label="Without Notification"
                />
              </RadioGroup>
            )}
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </Popover>
      </Stack>
    );
  }, [value, resultsMap, isReadyToDeclare, menuOpen, notiOption]);

  if (item.isHoliday) {
    return (
      <Label variant="filled" color="warning">
        <Typography>Holiday</Typography>
      </Label>
    );
  }
  if (item.isOff) {
    return (
      <Label variant="outlined" color="error">
        <Typography>Off Day</Typography>
      </Label>
    );
  }

  if (isReadyToDeclare)
    return (
      <>
        {memoizeComponent}
        {/* <Prompt
          ref={confirmRef}
          title="Declare Result"
          text="How do you want to declare the result?"
          positiveButtonText="With Notification"
          negativeButtonText="Without Notification"
          positiveAction={() => handleOptions(true)}
          negativeAction={() => handleOptions(false)}
        /> */}
      </>
    );
  return <>-</>;
}
