import { useState } from "react";
import { useQuery } from "react-query";

export default function usePaginatedQuery(key, queryFn) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const pagination = {
    page,
    limit,
    setPage,
    setLimit,
    totalPages,
    setTotalPages,
    totalResults,
    setTotalResults,
  };

  const pageParams = { page: pagination.page, limit: pagination.limit };
  const queryResult = useQuery([key, pageParams], () => queryFn(pageParams), {
    onSuccess: (res) => {
      if (res.status === 1) {
        pagination.setTotalPages(res.totalPages);
        pagination.setTotalResults(res.totalResults);
      }
    },
  });

  return {
    pagination: pagination,
    ...queryResult,
  };
}
