import { useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  ButtonBase,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Link,
  Tooltip,
} from "@mui/material";
import Cipher from "basic-cipher";
// import * as Yup from "yup";
// components
import Page from "../components/Page";
// import Iconify from "../components/Iconify";
import CommonTable from "src/components/CommonTable";
// import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Label from "src/components/Label";
import { sentenceCase } from "change-case";
// import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi, { API_BASE_URL } from "src/api/api";
// import RowStack from "src/layouts/RowStack";
// import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import { format } from "date-fns";
import { CIPHER_KEY, getFormatedAddress } from "src/utils/commons";
import Iconify from "src/components/Iconify";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useAlert } from "src/hooks/useNotify";
import usePaginatedQuery from "src/hooks/usePaginatedQuery";
// import { getEndTime, getStartTime } from "src/utils/formatTime";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srNo", label: "Serial No.", alignRight: false },
  { id: "profilePic", label: "Profile Pic", alignRight: false },
  { id: "username", label: "User name", alignRight: false },
  {
    id: "phoneNumber",
    label: "Phone",
    alignRight: false,
  },
  { id: "createdAt", label: "Registered On", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "deviceName", label: "Device name", alignRight: false },
  { id: "deviceId", label: "Device Id", alignRight: false },
  { id: "isBlocked", label: "Blocked Status", alignRight: false },
  { id: "isActive", label: "User Online", alignRight: false },
  { id: "viewLogs", label: "View Logs" },
  { id: "viewDevices", label: "View Devices" },
  { id: "userBlock", label: "User Block/UnBlock" },
];

// ----------------------------------------------------------------------

export default function User() {
  const {
    isLoading,
    error,
    data = { data: [] },
    pagination,
  } = usePaginatedQuery(QUERY_KEYS.USERS_LIST, appsApi.getUsers);

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [isPhoneEncrypted, setIsPhoneEncrypted] = useState(true);
  const [key, setKey] = useState("");

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({
    userId: "",
    isBlocked: false,
  });

  const onClose = () => {
    setKey("");
    // setDecryptedResult("");
    setOpen(false);
  };

  const handleClose = () => setOpenDeleteConfirm(false);

  const onBlockUnblock = () => {
    const params = selectedUserData;
    userBlockUnBlockMutation.mutate({
      params,
      userId: selectedUserData.userId,
    });
    setOpenDeleteConfirm(false);
  };

  const handleBlockUnBlock = (userId, isBlocked) => {
    setOpenDeleteConfirm(true);
    setSelectedUserData({ userId, isBlocked });
  };

  const decryptPhone = (e) => {
    if (e?.target?.blur) {
      e.target.blur();
    }
    setOpen(true);
  };

  const encryptPhone = (e) => {
    if (e?.target?.blur) {
      e.target.blur();
    }
    setIsPhoneEncrypted(true);
  };

  const onPhoneDecrypt = () => {
    if (key === CIPHER_KEY) {
      setIsPhoneEncrypted(false);
      onClose();
    } else {
      setIsPhoneEncrypted(true);
      notify.toastError(`Incorrect secret key! Please try again!`);
    }
  };

  const unLockModal = useMemo(
    () => (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">View Phone Number</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter the secret key to view the phone numbers
          </DialogContentText>
          <TextField
            sx={{ mt: 2 }}
            size="small"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                onPhoneDecrypt();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onPhoneDecrypt}>Submit</Button>
        </DialogActions>
      </Dialog>
    ),
    [open, key, isPhoneEncrypted, onPhoneDecrypt],
  );

  const userBlockUnblockModal = useMemo(() => (
    <Dialog
      open={openDeleteConfirm}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {!selectedUserData.isBlocked
          ? "Unblock this device"
          : "Block this device"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to{" "}
          {!selectedUserData.isBlocked ? "Unblock" : "Block"} this entry?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={onBlockUnblock}>Yes</Button>
      </DialogActions>
    </Dialog>
  ));

  const userBlockUnBlockMutation = useMutation(appsApi.userBlockUnBlock, {
    onSuccess: (res) => {
      if (res.status === 1) {
        notify.toastSuccess(`User status updated Successully!`);
        handleClose();
        queryClient.invalidateQueries(QUERY_KEYS.USERS_LIST);
      }
    },
  });

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" mb={5} spacing={1}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Tooltip
            title={`${!isPhoneEncrypted ? "Encrypt" : "Decrypt"} Users Phone`}
          >
            <IconButton
              onClick={isPhoneEncrypted ? decryptPhone : encryptPhone}
            >
              <Iconify
                icon={isPhoneEncrypted ? "bxs:lock" : "bxs:lock-open"}
                width={28}
                height={28}
              />
            </IconButton>
          </Tooltip>
          {/* 
            <Button
              onClick={() => setOpenForm(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add User
            </Button> 
          */}
        </Stack>
        <Card>
          <CommonTable
            pagination={pagination}
            loading={isLoading}
            tableTitle="User"
            tableHead={TABLE_HEAD}
            list={data.data || []}
            RowItem={(params) => (
              <RowItem
                {...params}
                isPhoneEncrypted={isPhoneEncrypted}
                handleBlockUnBlock={handleBlockUnBlock}
              />
            )}
          />
        </Card>
        {unLockModal}
        {userBlockUnblockModal}
      </Container>
    </Page>
  );
}

const RowItem = ({ row, isPhoneEncrypted, handleBlockUnBlock, index }) => {
  const {
    _id,
    username,
    profilePic,
    phoneNumber,
    isActive,
    location,
    isBlocked,
    deviceData,
    createdAt,
  } = row;

  const [profileBASE64, setProfileBASE64] = useState("");
  let address = getFormatedAddress(location);
  const navigate = useNavigate();

  const handleDevices = (data = {}) => {
    navigate(`device/${_id}`, { state: { deviceData: data ?? [] } });
  };

  const handleLogs = () => {
    navigate(`log/${_id}`);
  };

  const imageUrlToBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((onSuccess, onError) => {
      try {
        const reader = new FileReader();
        reader.onload = function () {
          onSuccess(this.result);
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        onError(e);
      }
    });
  };

  const getProfilePic = async () => {
    let result = "";
    const response = await imageUrlToBase64(
      `${API_BASE_URL}user/read-image/${profilePic}`,
    );
    setProfileBASE64(response);
    return response;
  };

  const phone = !isPhoneEncrypted
    ? Cipher.decode(phoneNumber, CIPHER_KEY)
    : phoneNumber;

  return (
    <TableRow hover key={_id}>
      <TableCell align="left">{index}</TableCell>
      <TableCell component="th" scope="row">
        {profilePic ? (
          <Avatar
            src={`${profileBASE64.replace("data:text/html", "data:image/png")}`}
            dd={getProfilePic()}
            crossOrigin="anonymous"
          />
        ) : (
          <Avatar />
        )}
      </TableCell>
      <TableCell align="left">{username || "-"}</TableCell>
      <TableCell component="th" scope="row">
        <b>{phone}</b>
      </TableCell>
      <TableCell align="left">
        {format(new Date(createdAt), "MMMM dd, yyyy")}
      </TableCell>
      <TableCell align="left">{address}</TableCell>
      <TableCell align="left">{`${
        _.isArray(deviceData) && !_.isEmpty(deviceData)
          ? deviceData[0].deviceName
          : "-"
      }`}</TableCell>
      <TableCell align="left">{`${
        _.isArray(deviceData) && !_.isEmpty(deviceData)
          ? deviceData[0].deviceId
          : "-"
      }`}</TableCell>

      <TableCell align="left">
        <ButtonBase>
          <Label variant="ghost" color={isBlocked ? "error" : "success"}>
            {sentenceCase(isBlocked ? "Blocked" : "Not Blocked")}
          </Label>
        </ButtonBase>
      </TableCell>
      <TableCell align="left">
        <ButtonBase>
          <Label variant="ghost" color={!isActive ? "error" : "success"}>
            {sentenceCase(isActive ? "Online" : "Offline")}
          </Label>
        </ButtonBase>
      </TableCell>
      <TableCell align="right">
        <Stack direction="row">
          <IconButton
            sx={{
              color: "text.secondary",
              "&:hover": {
                color: "primary.main",
              },
            }}
            onClick={handleLogs}
          >
            <Iconify icon="carbon:view-filled" width={24} height={24} />
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <Stack direction="row">
          <IconButton
            sx={{
              color: "text.secondary",
              "&:hover": {
                color: "primary.main",
              },
            }}
            onClick={() => handleDevices(deviceData)}
          >
            <Iconify icon="carbon:view-filled" width={24} height={24} />
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title={`${!isBlocked ? "Block" : "UnBlock"}`}>
            <IconButton
              onClick={() => {
                handleBlockUnBlock(_id, !isBlocked);
              }}
            >
              <Iconify
                icon={isBlocked ? "ooui:un-block" : "ooui:block"}
                width={28}
                height={28}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
