import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
// Pages
import User from "./pages/User";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import DashboardApp from "./pages/DashboardApp";
import Market from "./pages/Market";
import News from "./pages/News";
import Result from "./pages/Result";
import Notification from "./pages/Notification";
import WeeklyPanaCharts from "./pages/WeeklyPanaCharts";
import WeeklyJodiCharts from "./pages/WeeklyJodiCharts";
import WeeklyOpenCloseCharts from "./pages/WeeklyOpenCloseCharts";
import MarketSettings from "./pages/MarketSettings";
import Logs from "./pages/Logs";
import DeviceDetails from "./pages/DeviceDetails";

const publicRoutes = [
  {
    path: "/",
    element: <LogoOnlyLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "login", element: <Login /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

const protectedRoutes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", element: <DashboardApp /> },
      {
        path: "user",
        children: [
          { path: "", element: <User /> },
          { path: "log/:userId", element: <Logs /> },
          { path: "device/:userId", element: <DeviceDetails /> },
        ],
      },
      { path: "market", element: <Market /> },
      { path: "market-setting", element: <MarketSettings /> },
      { path: "news", element: <News /> },
      { path: "weekly-patti", element: <WeeklyPanaCharts /> },
      { path: "weekly-jodi", element: <WeeklyJodiCharts /> },
      { path: "weekly-open-close", element: <WeeklyOpenCloseCharts /> },
      { path: "result", element: <Result /> },
      { path: "notification", element: <Notification /> },
    ],
  },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default function Router() {
  const { authToken } = useSelector((state) => state.reducer);
  if (authToken) return useRoutes(protectedRoutes);
  return useRoutes(publicRoutes);
}
