const QUERY_KEYS = {
  MARKET_LIST: "marketList",
  USERS_LIST: "usersList",
  DASHBOARD_DATA: "dashboardData",
  MARKET_RESULT_LIST: "marketResultList",
  MARKET_RESULT_CALENDAR: "marketResultCalendar",
  NOTIFICATION_LIST: "notificationList",
  WEEKLY_CHART_LIST_PANA: "weeklyChartListPana",
  WEEKLY_CHART_LIST_JODI: "weeklyChartListJodi",
  WEEKLY_CHART_LIST_OPEN: "weeklyChartListOpen",
  GAME_PASS: "gamePass",
  NEWS_LIST: "newsList",
  LOG: "log",
};

export default QUERY_KEYS;
