import { useEffect, useMemo, useState } from "react";
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  CircularProgress,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// components
import ListHead from "src/components/CommonTable/ListHead";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Label from "src/components/Label";
import { sentenceCase } from "change-case";
import FormSidebar from "src/layouts/dashboard/FormSidebar";
import appsApi from "src/api/api";
import RowStack from "src/layouts/RowStack";
import { useAlert } from "src/hooks/useNotify";
import QUERY_KEYS from "src/_mock/queryKeys";
import {
  getAllPanasOfType,
  getCurrentDateInUTC,
  getDigitFromPana,
  getEndTime,
  getFullResult,
  getFullTimeString,
  getStartTime,
  getWeekDays,
  PANA_TYPES,
  parseWeekDays,
  WEEK_DAYS,
} from "src/utils/commons";
import Scrollbar from "src/components/Scrollbar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  RHFAutocomplete,
  RHFColorPicker,
  RHFSwitch,
  RHFTextField,
  RHFTimePicker,
} from "src/components/hook-form";
import dayjs from "dayjs";
import MemoizedResultInput from "src/components/MemoizedResultInput";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Market Name", alignRight: false },
  // { id: "result", label: "Result", alignRight: false },
  { id: "openBidTime", label: "Open Bid Time", alignRight: false },
  { id: "openPana", label: "Open Pana", alignRight: false },
  { id: "closeBidTime", label: "Close Bid Time", alignRight: false },
  { id: "closePana", label: "Close Pana", alignRight: false },
  // { id: "timings", label: "Timings", alignRight: false },
  // { id: "weekdays", label: "Week Days", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: "order", label: "Order", alignRight: false },
  { id: null, label: null, alignRight: false },
  { id: null, label: null, alignRight: false },
];

// ----------------------------------------------------------------------

export default function Market() {
  const {
    refetch,
    isLoading,
    error,
    data = { data: [] },
  } = useQuery(QUERY_KEYS.MARKET_LIST, appsApi.getMarket);
  const theme = useTheme();

  const notify = useAlert();
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const isEditMode = typeof openForm == "object";

  //
  const [resultsMap, setResultsMap] = useState({});
  const [lock, setLock] = useState(true);
  const [showHolidayModal, setShowHolidayModal] = useState(false);

  useEffect(() => keepRefreshing(), []);

  const keepRefreshing = () => {
    setInterval(() => {
      refetch();
    }, 30000);
  };

  const onSuccess = (res, key) => {
    if (res.status === 1) {
      notify.toastSuccess(`Market ${key} Successully!`);
      setOpenForm(false);
      queryClient.invalidateQueries(QUERY_KEYS.MARKET_LIST);
      return;
    }
  };

  const createMarket = useMutation(appsApi.createMarket, {
    onSuccess: (res) => onSuccess(res, "Created"),
  });

  const updateMarket = useMutation(appsApi.updateMarket, {
    onSuccess: (res) => onSuccess(res, "Updated"),
  });

  const changeMarketOrder = useMutation(appsApi.changeMarketOrder);

  const deleteMarket = useMutation(appsApi.deleteMarket, {
    onSuccess: (res) => onSuccess(res, "Deleted"),
  });

  const onEdit = (item) => {
    setOpenForm(item);
  };

  const onDelete = (item) => deleteMarket.mutate(item._id);

  const handleLock = () => setLock(!lock);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let movedItems = reorder(
      data.data,
      result.source.index,
      result.destination.index,
    );
    queryClient.setQueryData(QUERY_KEYS.MARKET_LIST, {
      ...data,
      data: movedItems,
    });
    updateMarketOrder(movedItems);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? theme.palette.primary.lighter : "white",
    display: isDragging ? "table" : "table-row",
    ...draggableStyle,
  });

  const updateMarketOrder = (newList) => {
    const params = {
      changeMarketOrderData: newList.map((mkt, index) => {
        return {
          marketId: mkt._id,
          order: index + 1,
        };
      }),
    };
    changeMarketOrder.mutate(params);
  };

  /// ------------------- RESULT SECTION ---------------------------------

  useEffect(() => {
    if (data.data && !_.isEmpty(data.data)) {
      const rMap = {};
      _.map(data.data, (v) => {
        let openResult = v.result.split("-")[0];
        let closeResult = v.result.split("-")[2];
        if (!openResult || openResult.includes("*")) {
          openResult = "";
        }
        if (!closeResult || closeResult.includes("*")) {
          closeResult = "";
        }
        rMap[`open_${v._id}`] = openResult;
        rMap[`close_${v._id}`] = closeResult;
      });
      setResultsMap(rMap);
    }
  }, [data]);

  const declareResult = useMutation(appsApi.createMarketResults, {
    onSuccess: (res) => {
      if (res.status === 1) {
        notify.toastSuccess(`Result Declared Successully!`);
        queryClient.invalidateQueries(QUERY_KEYS.MARKET_RESULT_LIST);
        queryClient.invalidateQueries(QUERY_KEYS.MARKET_LIST);
        return;
      }
    },
  });

  const updateResult = useMutation(appsApi.updateMarketResults, {
    onSuccess: (res) => {
      if (res.status === 1) {
        notify.toastSuccess(`Result Updated Successully!`);
        queryClient.invalidateQueries(QUERY_KEYS.MARKET_RESULT_LIST);
        queryClient.invalidateQueries(QUERY_KEYS.MARKET_LIST);
        return;
      }
    },
  });

  const onSubmitResult = (
    id,
    marketDetails,
    isOpen,
    withNotification = false,
    isEditMode = false,
  ) => {
    const params = {
      marketId: marketDetails._id,
      marketName: marketDetails.name,
      isNotified: Boolean(withNotification),
    };
    params.resultDate = dayjs().format("DD/MM/YYYY");
    const val = resultsMap[id];
    const validList = getAllPanasOfType(PANA_TYPES.ANY);
    if (!String(val).trim()) {
      notify.toastError("Please enter result!!!");
      return false;
    }
    if (!validList.includes(String(val))) {
      notify.toastError("Please enter a valid result!!!");
      return false;
    }
    if (isOpen) {
      params.openPana = val;
      params.openDigit = getDigitFromPana(val);
    } else {
      params.closePana = val;
      params.closeDigit = getDigitFromPana(val);
    }

    if (isEditMode) {
      params._id = marketDetails.marketResultId;
      updateResult.mutate({ params });
    } else {
      declareResult.mutate(params);
    }
  };

  // ---- !END OF RESULT SECTION -------------------------------------

  // Manage Holidays
  const declareHoliday = useMutation(appsApi.declareHoliday, {
    onSuccess: (res) => {
      if (res.status === 1) {
        notify.toastSuccess(`Holiday status updated Successully!`);
        queryClient.invalidateQueries(QUERY_KEYS.MARKET_LIST);
        closeHolidayModal();
      }
    },
  });

  const openHolidayModal = (market) => {
    setShowHolidayModal(market);
  };

  const closeHolidayModal = () => {
    setShowHolidayModal(false);
  };

  const handleHoliday = (e) => {
    setShowHolidayModal({
      ...showHolidayModal,
      isHoliday: e.target.checked,
    });

    const params = {
      holidayDate: getCurrentDateInUTC(),
      isHoliday: e.target.checked,
    };

    declareHoliday.mutate({ params, marketId: showHolidayModal._id });
  };

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Market
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title={`${!lock ? "Lock" : "Unlock"} Market Order`}>
              <IconButton onClick={handleLock}>
                <Iconify
                  icon={lock ? "bxs:lock" : "bxs:lock-open"}
                  width={28}
                  height={28}
                />
              </IconButton>
            </Tooltip>

            <Button
              onClick={() => setOpenForm(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Market
            </Button>
          </Stack>
        </Stack>
        <Card>
          <Scrollbar>
            <DragDropContext onDragEnd={onDragEnd}>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={"asc"}
                    orderBy={""}
                    headLabel={TABLE_HEAD}
                    rowCount={data?.data.length || 0}
                    numSelected={0}
                    onRequestSort={() => {}}
                    onSelectAllClick={() => {}}
                  />
                  {isLoading ? (
                    <TableBody>
                      <TableRow style={{ height: 300 }}>
                        <TableCell colSpan={TABLE_HEAD.length}>
                          <Box w="100%" display="flex" justifyContent="center">
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <TableBody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {data.data.map((row, index) => {
                            const {
                              _id,
                              name,
                              order,
                              weekDays,
                              result,
                              timings,
                              isDisabled,
                            } = row;
                            return (
                              <Draggable
                                isDragDisabled={lock}
                                key={row._id + index}
                                draggableId={row._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <TableCell>{name}</TableCell>
                                    <TableCell align="left">
                                      {timings.split(" ")[0]}
                                    </TableCell>
                                    <TableCell>
                                      <MemoizedResultInput
                                        isOpen={true}
                                        resultsMap={resultsMap}
                                        setResultsMap={setResultsMap}
                                        onSubmitResult={onSubmitResult}
                                        item={row}
                                      />
                                    </TableCell>
                                    <TableCell align="left">
                                      {timings.split(" ")[2]}
                                    </TableCell>
                                    <TableCell>
                                      <MemoizedResultInput
                                        isOpen={false}
                                        resultsMap={resultsMap}
                                        setResultsMap={setResultsMap}
                                        onChange={(e) => {
                                          resultsMap[`close${_id}`] =
                                            e.target.value;
                                          setResultsMap({ ...resultsMap });
                                        }}
                                        onSubmitResult={onSubmitResult}
                                        item={row}
                                      />
                                    </TableCell>
                                    {/* <TableCell
                                      align="left"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      {result}
                                    </TableCell> */}
                                    {/* <TableCell align="left">
                                      {timings}
                                    </TableCell> */}
                                    {/* <TableCell align="left">
                                      {getWeekDaysFormatString(weekDays)}
                                    </TableCell> */}
                                    <TableCell align="left">
                                      <Label
                                        variant="ghost"
                                        color={isDisabled ? "error" : "success"}
                                      >
                                        {sentenceCase(
                                          isDisabled ? "Disabled" : "Enabled",
                                        )}
                                      </Label>
                                    </TableCell>
                                    {/* <TableCell align="left">{order}</TableCell> */}
                                    <TableCell align="right">
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => openHolidayModal(row)}
                                      >
                                        Holiday
                                      </Button>
                                    </TableCell>
                                    <TableCell align="right">
                                      <MoreMenu
                                        onEdit={() => onEdit(row)}
                                        onDelete={() => onDelete(row)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  )}
                </Table>
              </TableContainer>
            </DragDropContext>
          </Scrollbar>
        </Card>
        {openForm && (
          <MarketForm
            totalMarkets={data.data.length || 0}
            mode={isEditMode ? "UPDATE" : "CREATE"}
            isSubmitting={
              createMarket.isLoading ||
              updateMarket.isLoading ||
              deleteMarket.isLoading
            }
            openForm={openForm}
            setOpenForm={setOpenForm}
            onUpdate={updateMarket}
            onCreate={createMarket}
          />
        )}
        {!_.isEmpty(showHolidayModal) && (
          <Dialog
            open={!_.isEmpty(showHolidayModal)}
            onClose={closeHolidayModal}
          >
            <DialogTitle id="alert-dialog-title">Manage Holiday</DialogTitle>
            <DialogContent>
              <Stack direction="column">
                <Typography>
                  Market: <b>{showHolidayModal.name}</b>
                </Typography>
                <Typography>
                  Day: <b>{dayjs().format("DD MMM YYYY, dddd")}</b>
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showHolidayModal.isHoliday}
                      onChange={handleHoliday}
                    />
                  }
                  label={`Declare Holiday on ${dayjs().format(
                    "DD MMM YYYY, dddd",
                  )}`}
                />
              </Stack>
            </DialogContent>
          </Dialog>
        )}
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

const MarketForm = (props) => {
  const {
    openForm,
    setOpenForm,
    isSubmitting,
    mode,
    onUpdate,
    onCreate,
    totalMarkets,
  } = props;

  const onSubmitMarket = (vals) => {
    const params = {
      name: vals.name,
      timings: getFullTimeString(vals),
      order: Number(vals.order),
      isDisabled: vals.isDisabled,
      startTime: false,
      buttonColor: "#FFFFFF",
      backgroundColor: vals.backgroundColor,
      result: getFullResult(vals),
      endTime: false,
      isPrimeMarket: false,
      weekDays: getWeekDays(vals.weekDays),
    };
    if (mode == "CREATE") onCreate.mutate(params);
    if (mode == "UPDATE") {
      delete params.result;
      onUpdate.mutate({ params, id: openForm._id });
    }
  };

  let defaultValues = useMemo(() => {
    if (mode === "UPDATE") {
      return {
        name: openForm.name,
        startTime: getStartTime(openForm.timings),
        endTime: getEndTime(openForm.timings),
        order: openForm.order,
        isDisabled: openForm.isDisabled,
        weekDays: parseWeekDays(openForm.weekDays),
        backgroundColor: openForm.backgroundColor,
      };
    }
    return {
      name: "",
      startTime: getStartTime(),
      endTime: getEndTime(),
      order: Number(totalMarkets + 1),
      isDisabled: false,
      weekDays: WEEK_DAYS,
      backgroundColor: "#000000",
    };
  }, [openForm]);

  const MarketSchema = useMemo(() => {
    let validatorSchema = {
      name: Yup.string().required("Market name is required"),
      startTime: Yup.string().required("Start time is required"),
      endTime: Yup.string().required("End time is required"),
      backgroundColor: Yup.string().required("Color code is required"),
      order: Yup.number()
        .typeError("Order must be a number")
        .required("Order number is required")
        .min(0, "Order number must be between 0 and 100")
        .max(100, "Order number must be between 0 and 100"),
      isDisabled: Yup.bool(),
      weekDays: Yup.array().min(1, "At lease one day is requried"),
    };
    // if (mode === "UPDATE") {
    //   return Yup.object().shape(
    //     _.merge(validatorSchema, {
    //       openResult: Yup.string().required("Market result is required"),
    //       closeResult: Yup.string().required("Market result is required"),
    //     }),
    //   );
    // }
    return Yup.object().shape(validatorSchema);
  }, []);

  const methods = useForm({
    resolver: yupResolver(MarketSchema),
    defaultValues,
  });

  return (
    <FormSidebar
      formTitle={mode === "UPDATE" ? "Update Market" : "Create Market"}
      isOpenSidebar={Boolean(openForm)}
      onCloseSidebar={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmitMarket}
      isSubmitting={isSubmitting}
    >
      <RHFTextField name="name" label="Market Name" />
      <RowStack>
        <RHFTimePicker name="startTime" label="Open Time" />
        <RHFTimePicker name="endTime" label="Close Time" />
      </RowStack>
      <RHFAutocomplete
        multiple
        name="weekDays"
        label="Week Days"
        options={WEEK_DAYS}
      />
      <RHFColorPicker name="backgroundColor" label="Market Color" />
      <RHFSwitch name="isDisabled" label="Market Disabled" />
      {/* {mode === "UPDATE" && (
        <>
          <Label sx={{ py: 3, fontSize: 18 }}>Market Result</Label>
          <RowStack>
            <RHFAutocomplete
              name="openResult"
              label="Open Result"
              options={RESULT_OPTIONS}
            />
            <RHFAutocomplete
              name="closeResult"
              label="Close Result"
              options={RESULT_OPTIONS}
            />
          </RowStack>
        </>
      )} */}
    </FormSidebar>
  );
};
